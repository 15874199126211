/* Tailwind CSS in index.css or App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Example styles for Sidebar and Chat Container */
/* Adjust according to your design preferences */
.sidebar {
    width: 20rem; /* Adjust width as needed */
}

.chat-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
}

.bg-login-image {
  background-image: url('../public/1.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
}
